.partnership{
	position: relative;
	min-width: 320px;
	font-family: 'Roboto', sans-serif; 
	line-height: 1.2;
	color: #2D2D2D;
	margin-top: 79px;
	margin-bottom: 54px;
	counter-reset: partner;

	*{
		box-sizing: border-box;
	}

	*:before, *:after{
		box-sizing: border-box;
	}

	&__wrap{
		max-width: 1220px;
		padding: 0 40px;
		margin: 0 auto;
	}
	
	&__title{
		text-align: center;
		font-size: 52px;
		color: #D77312;
		text-transform: none;
		font-weight: 700;
		line-height: 1.2;
		margin: 0;
	}
	
	&__desc{
		font-size: 24px;
		font-weight: 700;
		color: #2D2D2D;
		max-width: 800px;
		line-height: 1.55;
		text-align: center;
		margin: 22px auto 0;
	}
	
	&__list{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 39px;
		position: relative;
		margin-bottom: 0;
		padding: 0;

		&:after{
			position: absolute;
			content: '';
			bottom: 73px;
			height: 20px;
			width: 100%;
			left: 0;
			background: linear-gradient(270deg, #137A3E -3.95%, #4F8C2E 50.81%, #72902A 103.33%);
		}
	}
	
	&__item{
		position: relative;
		width: 100%;
		max-width: 360px;
		list-style-type: none;

		&:first-child {
			.partnership{
				&__item-icon {
					&:after {
						border-top-color:#578629;
					}
				}

				&__icon-bg{
					&::before {
						background-image: linear-gradient(159.06deg, #6B902B 4.73%, #3A5F14 53.24%, #5D8E2D 93.44%);
					}
				}

				&__item-desc {
					&:before {
						border: 5px solid #578629;
					}

					&::after {
						background-color:#578629;
					}
				}
			}
		}

		&:nth-child(2) {
			.partnership{
				&__item-icon {
					&:after {
						border-top-color:#508B2D;
					}
				}

				&__icon-bg {
					&::before {
						background-image: linear-gradient(180deg, #518D2E 0%, #2A5B0E 50%, #518D2E 100%);
					}
				}

				&__item-desc {
					&:before {
						border: 5px solid #508D2E;
					}

					&::after {
						background-color:#508D2E;
					}
				}
			}
		}

		&:last-child {
			.partnership{
				&__item-icon {
					&:after {
						border-top-color:#207736;
					}
				}

				&__icon-bg {
					&::before {
						background-image: linear-gradient(180deg, #237F3A 0%, #0B4218 50%, #237F3A 100%);
					}
				}

				&__item-desc {
					&:before {
						border: 5px solid #237F3A;
					}

					&::after {
						background-color:#237F3A;
					}
				}
			}
		}
	}
	
	&__item-icon{
		position: relative;
		width: 157px;
		height: 157px;
		border-radius: 50%;
		margin: 0 auto;

		&:after{
			position: absolute;
			content: '';
			bottom: -24px;
			left: 50%;
			width: 10px;
			height: 10px;
			transform: translateX(-50%);
			border: 7px solid transparent;
			border-top: 18px solid transparent;
		}
	}

	&__icon-bg{
		position: absolute;
		z-index: 2;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 105px;
		height: 105px;
		border-radius: 50%;
		box-shadow: 0px 4px 10px rgba(124, 124, 124, 0.25);
		margin: 0;

		span{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-radius: 50%;
			box-shadow: 0px 4px 10px rgba(124, 124, 124, 0.25);
			background-color: #fff;
		}

		&::before,
		&:after {
			position: absolute;
			content: '';
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-radius: 50%;
		}

		&::before {
			z-index: -1;
			width: 150%;
			height: 150%;
		}

		&:after {
			z-index: -1;
			width: 120%;
			height: 120%;
			background-color: #fff;
		}
	}
	
	&__item-img{
		position: absolute;
		z-index: 1;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 49px;
		height: 49px;
		object-fit: contain;
	}

	&__item-desc{
		position: relative;
		font-size: 22px;
		text-align: center;
		color: #383838;
		font-weight: 500;
		margin-top: 110px;
		margin-bottom: 0;
		letter-spacing: 0.5px;

		&:before,
		&:after {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			border-radius: 50%;
		}

		&:before {
			z-index: 1;
			top: -84px;
			content: '';
			width: 55px;
			height: 55px;
			background: #FFFFFF;
		}

		&:after{
			counter-increment: partner;
			content:counter(partner);
			top: -72px;
			z-index: 2;
			width: 31px;
			height: 31px;
			line-height: 2;
			font-size: 16px;
			font-weight: 500;
			text-align: center;
			color: #fff;
		}
	}
	
	&__video-wrap{
		margin-top: 33px;
		position: relative;
		padding-bottom: 50%;
		border-radius: 10px;
		backdrop-filter: blur(2px);

		&.load{
			backdrop-filter: none;

			.partnership{
				&__video-play,
				&__placeholder {
					display: none;
				}
			}
		}

		&:hover, &:focus{
			.partnership{
				&__video-play {
					transform: translate(-50%, -50%) scale(0.9);
				}
			}
		}
	}
	
	&__video-play{
		position: absolute;
		z-index: 2;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transform-origin: center;
		width: 85px;
		border-radius: 50%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		background-image: url(../img/sprite.svg#play);
		height: 85px;
		transition: .2s;
		cursor: pointer;
		border: none;
		background-color: transparent;

		outline: none;
	}
	
	&__placeholder{
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		cursor: pointer;
	}
	
	&__video{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		border-radius: 10px;
	}
	
	&__link{
		display: block; 
		text-align: center;
		margin-top: 27px;
		color: #018338;
		text-decoration: underline;
		transition: .2s;
		font-weight: 600;
		font-size: 18px;

		&:hover, &:focus{
			color: #009540;
		}
	}

	&__second-desc{
		margin-top: 50px;
		font-size: 20px;
		color: #2D2D2D;
		line-height: 1.4;
	}
}

@media(max-width: 1100px){
	.partnership{
		&__wrap{
			padding: 0 10px;
		}

		&__title{
			font-size: 42px;
		}

		&__desc{
			font-size: 20px;
		}

		&__list{
			&:after{
				bottom: 68px;
			}
		}

		&__item-desc{
			font-size: 18px;
		}

		&__link{
			font-size: 16px;
		}

		&__second-desc{
			font-size: 16px;
		}
	}
}

@media(max-width: 767px) {
	.partnership {
		margin-top: 60px;	

		&__wrap {
			padding: 0 5px;
		}

		&__title {
			font-size: 36px;
		}

		&__desc {
			font-size: 18px;
		}

		&__list {
			&:after {
				height: 10px;
			}
		}

		&__item-icon {
			width: 120px;
			height: 120px;

			&:after{
				bottom: -18px;
			}
		}

		&__icon-bg {
			width: 78px;
			height: 78px;
		}

		&__item-img {
			width: 35px;
			height: 35px;
		}

		&__item-desc {
			font-size: 14px;
			margin-top: 100px;

			&:before{
				top: -79px;
				width: 45px;
				height: 45px;
			}

			&:after{
				top: -67px;
				width: 21px;
				height: 21px;
				font-size: 11px;
				letter-spacing: -1px;
			}
		}

		&__video-wrap {
			padding-bottom: 70%;
			border-radius: 10px;
			overflow: hidden;
		}

		&__video-play {
			width: 55px;
			height: 55px;
		}

		&__second-desc {
			margin-top: 40px;
		}
	}
}

@media(max-width: 550px) {
	.partnership {
		margin-top: 40px;

		&__wrap {
			padding: 0;
		}

		&__title {
			font-size: 32px;
		}

		&__desc {
			font-size: 16px;
		}

		&__list {
			&:after {
				height: 10px;
			}
		}

		&__item-icon {
			width: 90px;
			height: 90px;

			&:after {
				bottom: -10px;
			}
		}

		&__icon-bg {
			width: 50px;
			height: 50px;
		}

		&__item-img {
			width: 25px;
			height: 25px;
		}

		&__item-desc {
			font-size: 14px;
			max-width: 100px;
			margin: 70px auto 0;

			&:before {
				top: -57px;
				width: 35px;
				height: 35px;
			}

			&:after {
				top: -47px;
				width: 15px;
				height: 15px;
				font-size: 8px;
				letter-spacing: 0;
			}
		}

		&__video-wrap {
			padding-bottom: 80%;
		}

		&__second-desc {
			margin-top: 30px;
		}
	}
}