@charset "UTF-8";

// Default Variables

@slick-font-path: "./fonts/";
@slick-font-family: "slick";
@slick-loader-path: "./";
@slick-arrow-color: white;
@slick-dot-color: #fff;
@slick-dot-color-active: #fff;
@slick-prev-character: "←";
@slick-next-character: "→";
@slick-dot-character: "•";
@slick-dot-size: 6px;
@slick-opacity-default: 0.75;
@slick-opacity-on-hover: 1;
@slick-opacity-not-active: 0.25;

/* Arrows */
.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 82px;
    width: 82px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    padding: 0;
    border: none;
    outline: none;
    transition: .2s;

    &:hover,
    &:focus {
        outline: none;
        color: transparent;

        &:before {
            opacity: @slick-opacity-on-hover;
        }
    }

    &.slick-disabled {
        cursor: default;
        pointer-events: none;
        cursor: not-allowed;
    }
}

.slick-prev:before,
.slick-next:before {
    font-family: @slick-font-family;
    font-size: 12px;
    line-height: 1;
    color: @slick-arrow-color;
    opacity: @slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    & when (@slick-font-family ='slick') {

        /* Icons */
        @font-face {
            font-family: 'slick';
            font-weight: normal;
            font-style: normal;
        }
    }
}

.slick-prev {
    left: 40px;
    z-index: 1;

    [dir="rtl"] & {
        left: auto;
        right: 40px;
    }

    &:before {
        position: absolute;
        top: 30px;
        left: 14px;
        content: '';
        border-bottom: 2px solid #fff;
        border-left: 2px solid #fff;
        transform: rotate(45deg);
        width: 10px;
        height: 10px;

        [dir="rtl"] & {
            content: @slick-next-character;
        }
    }
}

.slick-next {
    right: 40px;

    [dir="rtl"] & {
        left: 40px;
        right: auto;
    }

    &:before {
        position: absolute;
        top: 29px;
        right: 15px;
        content: '';
        border-bottom: 2px solid #fff;
        border-left: 2px solid #fff;
        opacity: 1;
        transform: rotate(-135deg);
        width: 10px;
        height: 10px;

        [dir="rtl"] & {
            content: @slick-prev-character;
        }
    }
}

/* Dots */

.slick-dotted .slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    z-index: 2;
    list-style: none;
    text-align: center;
    padding: 0;
    margin: 0;
    right: 15.1%;
    top: 58px;
    align-items: center;
    bottom: initial;
    left: initial;
    flex-direction: column;
    height: max-content;
    width: max-content;

    li+li {
        margin-left: 0;
        margin-top: 39px;
    }

    li {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 10px;
        width: 10px;
        padding: 0;
        cursor: pointer;

        button {
            position: relative;
            border: 0;
            background: transparent;
            display: block;
            height: 10px;
            width: 10px;
            outline: none;
            color: transparent;
            padding: 4px;
            cursor: pointer;

            &:hover,
            &:focus {
                outline: none;

                &:before {
                    background-color: #fff;
                }
            }

            &:before {
                position: absolute;
                content: '';
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: gray;
                width: 5px;
                height: 5px;
                transition: .2s;
                border-radius: 0;
                border: 1px solid transparent;
            }
        }

        &.slick-active button {
            &:before {
                border: 2px solid gray;
                width: 12px;
                height: 12px;
                background-color: transparent;
                border-radius: 0;
            }

            &:hover,
            &:focus {
                &:before {
                    border: 2px solid gray;
                    border-radius: 0;
                }
            }
        }
    }
}

@media(max-width: 850px) {
    .slick-dots {
        flex-direction: row;
        align-items: center;
        transform: none;
        right: initial;
        left: 0;
        justify-content: center;
        width: 100%;

        li+li {
            margin-left: 39px;
            margin-top: 0;
        }
    }
}