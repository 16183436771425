@charset "UTF-8";
.partnership {
  position: relative;
  min-width: 320px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.2;
  color: #2D2D2D;
  margin-top: 79px;
  margin-bottom: 54px;
  counter-reset: partner;
}
.partnership * {
  box-sizing: border-box;
}
.partnership *:before,
.partnership *:after {
  box-sizing: border-box;
}
.partnership__wrap {
  max-width: 1220px;
  padding: 0 40px;
  margin: 0 auto;
}
.partnership__title {
  text-align: center;
  font-size: 52px;
  color: #D77312;
  text-transform: none;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
}
.partnership__desc {
  font-size: 24px;
  font-weight: 700;
  color: #2D2D2D;
  max-width: 800px;
  line-height: 1.55;
  text-align: center;
  margin: 22px auto 0;
}
.partnership__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 39px;
  position: relative;
  margin-bottom: 0;
  padding: 0;
}
.partnership__list:after {
  position: absolute;
  content: '';
  bottom: 73px;
  height: 20px;
  width: 100%;
  left: 0;
  background: linear-gradient(270deg, #137A3E -3.95%, #4F8C2E 50.81%, #72902A 103.33%);
}
.partnership__item {
  position: relative;
  width: 100%;
  max-width: 360px;
  list-style-type: none;
}
.partnership__item:first-child .partnership__item-icon:after {
  border-top-color: #578629;
}
.partnership__item:first-child .partnership__icon-bg::before {
  background-image: linear-gradient(159.06deg, #6B902B 4.73%, #3A5F14 53.24%, #5D8E2D 93.44%);
}
.partnership__item:first-child .partnership__item-desc:before {
  border: 5px solid #578629;
}
.partnership__item:first-child .partnership__item-desc::after {
  background-color: #578629;
}
.partnership__item:nth-child(2) .partnership__item-icon:after {
  border-top-color: #508B2D;
}
.partnership__item:nth-child(2) .partnership__icon-bg::before {
  background-image: linear-gradient(180deg, #518D2E 0%, #2A5B0E 50%, #518D2E 100%);
}
.partnership__item:nth-child(2) .partnership__item-desc:before {
  border: 5px solid #508D2E;
}
.partnership__item:nth-child(2) .partnership__item-desc::after {
  background-color: #508D2E;
}
.partnership__item:last-child .partnership__item-icon:after {
  border-top-color: #207736;
}
.partnership__item:last-child .partnership__icon-bg::before {
  background-image: linear-gradient(180deg, #237F3A 0%, #0B4218 50%, #237F3A 100%);
}
.partnership__item:last-child .partnership__item-desc:before {
  border: 5px solid #237F3A;
}
.partnership__item:last-child .partnership__item-desc::after {
  background-color: #237F3A;
}
.partnership__item-icon {
  position: relative;
  width: 157px;
  height: 157px;
  border-radius: 50%;
  margin: 0 auto;
}
.partnership__item-icon:after {
  position: absolute;
  content: '';
  bottom: -24px;
  left: 50%;
  width: 10px;
  height: 10px;
  transform: translateX(-50%);
  border: 7px solid transparent;
  border-top: 18px solid transparent;
}
.partnership__icon-bg {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 105px;
  height: 105px;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(124, 124, 124, 0.25);
  margin: 0;
}
.partnership__icon-bg span {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(124, 124, 124, 0.25);
  background-color: #fff;
}
.partnership__icon-bg::before,
.partnership__icon-bg:after {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.partnership__icon-bg::before {
  z-index: -1;
  width: 150%;
  height: 150%;
}
.partnership__icon-bg:after {
  z-index: -1;
  width: 120%;
  height: 120%;
  background-color: #fff;
}
.partnership__item-img {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 49px;
  height: 49px;
  object-fit: contain;
}
.partnership__item-desc {
  position: relative;
  font-size: 22px;
  text-align: center;
  color: #383838;
  font-weight: 500;
  margin-top: 110px;
  margin-bottom: 0;
  letter-spacing: 0.5px;
}
.partnership__item-desc:before,
.partnership__item-desc:after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
}
.partnership__item-desc:before {
  z-index: 1;
  top: -84px;
  content: '';
  width: 55px;
  height: 55px;
  background: #FFFFFF;
}
.partnership__item-desc:after {
  counter-increment: partner;
  content: counter(partner);
  top: -72px;
  z-index: 2;
  width: 31px;
  height: 31px;
  line-height: 2;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #fff;
}
.partnership__video-wrap {
  margin-top: 33px;
  position: relative;
  padding-bottom: 50%;
  border-radius: 10px;
  backdrop-filter: blur(2px);
}
.partnership__video-wrap.load {
  backdrop-filter: none;
}
.partnership__video-wrap.load .partnership__video-play,
.partnership__video-wrap.load .partnership__placeholder {
  display: none;
}
.partnership__video-wrap:hover .partnership__video-play,
.partnership__video-wrap:focus .partnership__video-play {
  transform: translate(-50%, -50%) scale(0.9);
}
.partnership__video-play {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  width: 85px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(../img/sprite.svg#play);
  height: 85px;
  transition: 0.2s;
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
}
.partnership__placeholder {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}
.partnership__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}
.partnership__link {
  display: block;
  text-align: center;
  margin-top: 27px;
  color: #018338;
  text-decoration: underline;
  transition: 0.2s;
  font-weight: 600;
  font-size: 18px;
}
.partnership__link:hover,
.partnership__link:focus {
  color: #009540;
}
.partnership__second-desc {
  margin-top: 50px;
  font-size: 20px;
  color: #2D2D2D;
  line-height: 1.4;
}
@media (max-width: 1100px) {
  .partnership__wrap {
    padding: 0 10px;
  }
  .partnership__title {
    font-size: 42px;
  }
  .partnership__desc {
    font-size: 20px;
  }
  .partnership__list:after {
    bottom: 68px;
  }
  .partnership__item-desc {
    font-size: 18px;
  }
  .partnership__link {
    font-size: 16px;
  }
  .partnership__second-desc {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .partnership {
    margin-top: 60px;
  }
  .partnership__wrap {
    padding: 0 5px;
  }
  .partnership__title {
    font-size: 36px;
  }
  .partnership__desc {
    font-size: 18px;
  }
  .partnership__list:after {
    height: 10px;
  }
  .partnership__item-icon {
    width: 120px;
    height: 120px;
  }
  .partnership__item-icon:after {
    bottom: -18px;
  }
  .partnership__icon-bg {
    width: 78px;
    height: 78px;
  }
  .partnership__item-img {
    width: 35px;
    height: 35px;
  }
  .partnership__item-desc {
    font-size: 14px;
    margin-top: 100px;
  }
  .partnership__item-desc:before {
    top: -79px;
    width: 45px;
    height: 45px;
  }
  .partnership__item-desc:after {
    top: -67px;
    width: 21px;
    height: 21px;
    font-size: 11px;
    letter-spacing: -1px;
  }
  .partnership__video-wrap {
    padding-bottom: 70%;
    border-radius: 10px;
    overflow: hidden;
  }
  .partnership__video-play {
    width: 55px;
    height: 55px;
  }
  .partnership__second-desc {
    margin-top: 40px;
  }
}
@media (max-width: 550px) {
  .partnership {
    margin-top: 40px;
  }
  .partnership__wrap {
    padding: 0;
  }
  .partnership__title {
    font-size: 32px;
  }
  .partnership__desc {
    font-size: 16px;
  }
  .partnership__list:after {
    height: 10px;
  }
  .partnership__item-icon {
    width: 90px;
    height: 90px;
  }
  .partnership__item-icon:after {
    bottom: -10px;
  }
  .partnership__icon-bg {
    width: 50px;
    height: 50px;
  }
  .partnership__item-img {
    width: 25px;
    height: 25px;
  }
  .partnership__item-desc {
    font-size: 14px;
    max-width: 100px;
    margin: 70px auto 0;
  }
  .partnership__item-desc:before {
    top: -57px;
    width: 35px;
    height: 35px;
  }
  .partnership__item-desc:after {
    top: -47px;
    width: 15px;
    height: 15px;
    font-size: 8px;
    letter-spacing: 0;
  }
  .partnership__video-wrap {
    padding-bottom: 80%;
  }
  .partnership__second-desc {
    margin-top: 30px;
  }
}
.nowrap {
  white-space: nowrap;
}
.hidden {
  display: none!important;
}
.scroll-stop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}
/*.stop-body-scroll{
  overflow: hidden;
  position: relative;
  height: 100%;
}*/
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 82px;
  width: 82px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  padding: 0;
  border: none;
  outline: none;
  transition: 0.2s;
}
.slick-prev:hover,
.slick-next:hover,
.slick-prev:focus,
.slick-next:focus {
  outline: none;
  color: transparent;
}
.slick-prev:hover:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  cursor: default;
  pointer-events: none;
  cursor: not-allowed;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 12px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Icons */
}
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
}
.slick-prev {
  left: 40px;
  z-index: 1;
}
[dir="rtl"] .slick-prev {
  left: auto;
  right: 40px;
}
.slick-prev:before {
  position: absolute;
  top: 30px;
  left: 14px;
  content: '';
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
}
[dir="rtl"] .slick-prev:before {
  content: "→";
}
.slick-next {
  right: 40px;
}
[dir="rtl"] .slick-next {
  left: 40px;
  right: auto;
}
.slick-next:before {
  position: absolute;
  top: 29px;
  right: 15px;
  content: '';
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  opacity: 1;
  transform: rotate(-135deg);
  width: 10px;
  height: 10px;
}
[dir="rtl"] .slick-next:before {
  content: "←";
}
/* Dots */
.slick-dotted .slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: center;
  padding: 0;
  margin: 0;
  right: 15.1%;
  top: 58px;
  align-items: center;
  bottom: initial;
  left: initial;
  flex-direction: column;
  height: max-content;
  width: max-content;
}
.slick-dots li + li {
  margin-left: 0;
  margin-top: 39px;
}
.slick-dots li {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 10px;
  width: 10px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  position: relative;
  border: 0;
  background: transparent;
  display: block;
  height: 10px;
  width: 10px;
  outline: none;
  color: transparent;
  padding: 4px;
  cursor: pointer;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  background-color: #fff;
}
.slick-dots li button:before {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: gray;
  width: 5px;
  height: 5px;
  transition: 0.2s;
  border-radius: 0;
  border: 1px solid transparent;
}
.slick-dots li.slick-active button:before {
  border: 2px solid gray;
  width: 12px;
  height: 12px;
  background-color: transparent;
  border-radius: 0;
}
.slick-dots li.slick-active button:hover:before,
.slick-dots li.slick-active button:focus:before {
  border: 2px solid gray;
  border-radius: 0;
}
@media (max-width: 850px) {
  .slick-dots {
    flex-direction: row;
    align-items: center;
    transform: none;
    right: initial;
    left: 0;
    justify-content: center;
    width: 100%;
  }
  .slick-dots li + li {
    margin-left: 39px;
    margin-top: 0;
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-slider .slick-slide + .slick-slide {
  display: none;
}
.slick-slider.slick-initialized .slick-slide + .slick-slide {
  display: block;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
}
.slick-arrow.slick-hidden {
  display: none;
}
