.nowrap{
  white-space: nowrap;
}
 
.hidden{
  display: none!important; 
}

.scroll-stop{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.visually-hidden{
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

/*.stop-body-scroll{
  overflow: hidden;
  position: relative;
  height: 100%;
}*/
